import { AuthenticatedUser } from "../api/types";

import useAuth from "./useAuth";

export interface UseUserProps {
  isAcademicInstitution: () => boolean;
  userType: () => string | undefined;
  businessTypeShortForm: () => string | undefined;
  assetsUnderManagementShortForm: () => string | null;
  provider: () => string | null;
  isAdmin: () => boolean;
  isCustomer: () => boolean;
  isGisSalesTeam: () => boolean;
  isProfEnterprise: () => boolean;
  isProfLite: () => boolean;
  isRetail: () => boolean;
  isProfessional: () => boolean;
  isPersonalType: () => boolean;
  isBusinessType: () => boolean;
  isAcademicType: () => boolean;
  isLegacy: () => boolean;
  passwordExpired: () => boolean;
  passwordExpiring: () => boolean;
  hasOrganizations: () => boolean;
  shouldShowOrgName: () => boolean;
  shouldConfirm: () => boolean;
  samlSsoMigrationPending: () => boolean;
  samlSsoMigrationPendingComplete: () => boolean;
  samlSsoMigrationComplete: () => boolean;
  canManageProfile: () => boolean;
  canManageSegmentation: () => boolean;
  canSetupTrialsAndSubscriptions: () => boolean;
  canDeleteAccount: () => boolean;
  hasDatalinkManagerAccess: () => boolean;
  hasAIPlaygroundAccess: () => boolean;
  organizationSftpEnabled: () => boolean;
}

function useUser(): UseUserProps {
  const STUDENT_USAGE_UNIVERSITY_WIDE = "STUDENT_USAGE_UNIVERSITY_WIDE";
  const SELL_SIDE_INSTITUTION = "SELL_SIDE_INSTITUTION";
  const BUY_SIDE_INSTITUTION = "BUY_SIDE_INSTITUTION";
  const GREATER_THAN_ONE_B = "GREATER_THAN_ONE_B";

  const { authenticatedUser: baseAuthenticatedUser } = useAuth();
  // TODO: reconcile multiple `AuthenticatedUser` type
  // converting to the new `AuthenticatedUser` (from /api/types) for now
  // to too many changes from updating `AuthenticatedUser` type in `useAuth`
  const authenticatedUser =
    baseAuthenticatedUser as unknown as AuthenticatedUser;

  const sanitizedRolesArray = () => {
    return Array.isArray(authenticatedUser?.roles_array)
      ? authenticatedUser?.roles_array
      : [];
  };

  const isAcademicInstitution = () => {
    return (
      authenticatedUser?.planned_data_usage_short_form ===
      STUDENT_USAGE_UNIVERSITY_WIDE
    );
  };

  const isAdmin = () => sanitizedRolesArray()?.includes("admin");
  const isCustomer = () => !!authenticatedUser?.customer_id;
  const hasOrganizations = () => !!authenticatedUser?.organization_ids?.length;

  const isGisSalesTeam = () =>
    sanitizedRolesArray()?.includes("gis_sales_team");

  const userType = () => {
    return authenticatedUser?.user_type;
  };

  const provider = () => authenticatedUser?.provider;

  const businessTypeShortForm = () => {
    return authenticatedUser?.business_type_short_form;
  };

  const assetsUnderManagementShortForm = () => {
    return authenticatedUser?.assets_under_management_short_form;
  };

  const isProfEnterprise = () => {
    return (
      businessTypeShortForm() === SELL_SIDE_INSTITUTION ||
      (businessTypeShortForm() === BUY_SIDE_INSTITUTION &&
        assetsUnderManagementShortForm() === GREATER_THAN_ONE_B)
    );
  };

  const passwordExpired = () =>
    authenticatedUser?.password_status === "PASSWORD_EXPIRED";

  const passwordExpiring = () =>
    authenticatedUser?.password_status === "PASSWORD_WARN";

  const isProfLite = () => {
    return (
      (userType() === "academic" && isAcademicInstitution()) ||
      (userType() === "business" && !isProfEnterprise())
    );
  };

  const isRetail = () => {
    return (
      userType() === "personal" ||
      (userType() === "academic" && !isAcademicInstitution())
    );
  };

  const shouldConfirm = () => {
    return authenticatedUser?.should_confirm;
  };

  const isProfessional = () => {
    return isProfEnterprise() || isProfLite();
  };

  const isPersonalType = () => userType() === "personal";
  const isAcademicType = () => userType() === "academic";
  const isBusinessType = () => userType() === "business";
  const isLegacy = () =>
    !(isPersonalType() || isBusinessType() || isAcademicType());
  const shouldShowOrgName = () => isBusinessType() || isAcademicType();

  const samlSsoMigrationComplete = () =>
    authenticatedUser?.saml_sso_migration_status === "complete";
  const samlSsoMigrationPending = () =>
    authenticatedUser?.saml_sso_migration_status === "pending";
  const samlSsoMigrationPendingComplete = () =>
    authenticatedUser?.saml_sso_migration_status === "pending_complete";
  const canManageProfile = () => authenticatedUser?.manage_profile_enabled;
  const canManageSegmentation = () => authenticatedUser?.segmentation_enabled;
  const canDeleteAccount = () => authenticatedUser?.can_delete;

  // Permissions
  const canSetupTrialsAndSubscriptions = () => isAdmin() || isGisSalesTeam();
  const hasDatalinkManagerAccess = () =>
    sanitizedRolesArray()?.includes("manager_admin") ||
    sanitizedRolesArray()?.includes("manager_publisher_user") ||
    sanitizedRolesArray()?.includes("manager_organization_user");
  const hasAIPlaygroundAccess = () => {
    return (
      authenticatedUser?.confirm_email?.includes("nasdaq.com") ||
      authenticatedUser?.confirm_email?.includes("quandl.com")
    );
  };
  const organizationSftpEnabled = () =>
    authenticatedUser?.organization_sftp_enabled;

  // Return the state and functions to manipulate the state
  return {
    isAcademicInstitution,
    userType,
    provider,
    businessTypeShortForm,
    assetsUnderManagementShortForm,
    isAdmin,
    isCustomer,
    isGisSalesTeam,
    isProfEnterprise,
    isProfLite,
    isRetail,
    isProfessional,
    isPersonalType,
    isBusinessType,
    isAcademicType,
    isLegacy,
    passwordExpired,
    passwordExpiring,
    hasOrganizations,
    shouldShowOrgName,
    shouldConfirm,
    samlSsoMigrationPending,
    samlSsoMigrationPendingComplete,
    samlSsoMigrationComplete,
    canManageProfile,
    canManageSegmentation,
    canDeleteAccount,
    canSetupTrialsAndSubscriptions,
    hasDatalinkManagerAccess,
    hasAIPlaygroundAccess,
    organizationSftpEnabled
  };
}

export default useUser;
