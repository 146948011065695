import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ProtectedRoute } from "./components/authentication";
import ContentfulPageOrNoMatch from "./components/contentful-page-or-no-match";
import AdminRoute from "./components/authentication/AdminRoute";
import useTrackCurrentPage from "./analytics/hooks/useTrackCurrentPage";
import ProductSubrouteRedirect from "./components/product-subroute-redirect";
import OrganizationDashboard from "./pages/page/organization-db";
import PublicOnlyRoute from "./components/authentication/PublicOnlyRoute";
import RedirectWithQuery from "./components/authentication/RedirectWithQuery";

const AccountPage = React.lazy(() => import("./pages/page/account/index"));
const AccountOrganizationsPage = React.lazy(
  () => import("./pages/page/account/organizations/index")
);
const AccountPasswordPage = React.lazy(
  () => import("./pages/page/account/password/index")
);
const AccountProfilePage = React.lazy(
  () => import("./pages/page/account/profile/index")
);
const AccountSubscriptionsInvoiceDetailPage = React.lazy(
  () => import("./pages/page/account/subscriptions/index")
);
const AccountSubscriptionsInvoicePage = React.lazy(
  () => import("./pages/page/account/subscriptions/index")
);
const AccountSubscriptionsBillingPage = React.lazy(
  () => import("./pages/page/account/subscriptions/index")
);
const AccountSubscriptionsPage = React.lazy(
  () => import("./pages/page/account/subscriptions/index")
);
const AccountSftpSettingsPage = React.lazy(
  () => import("./pages/page/account/sftp-settings/index")
);
const BookmarksPage = React.lazy(() => import("./pages/page/bookmarks/index"));
const DTCProductPricingThankYouPage = React.lazy(
  () =>
    import(
      "./pages/page/product/data-collection/[productName]/pricing/thank-you/index"
    )
);
const DTCProductPricingCheckoutPage = React.lazy(
  () =>
    import(
      "./pages/page/product/data-collection/[productName]/pricing/checkout/index"
    )
);
const DTCProductPricingPlansPage = React.lazy(
  () =>
    import(
      "./pages/page/product/data-collection/[productName]/pricing/plans/index"
    )
);
const TimeSeriesPlanPage = React.lazy(
  () =>
    import("./pages/page/product/database/[productName]/pricing/plans/index")
);
const DTCTermPage = React.lazy(
  () =>
    import(
      "./pages/page/product/data-collection/[productName]/pricing/terms/[licenceId]/index"
    )
);
const TableTermPage = React.lazy(
  () =>
    import(
      "./pages/page/product/database/[productName]/pricing/terms/[licenceId]/index"
    )
);
const ForgotPasswordPage = React.lazy(
  () => import("./pages/page/forgot-password/index")
);
const ResetPasswordPage = React.lazy(
  () => import("./pages/page/reset-password/index")
);
const HomePage = React.lazy(() => import("./pages/page/home/index"));
const JsonWidgetPage = React.lazy(
  () => import("./pages/page/json-widget/index")
);
const LoginPage = React.lazy(() => import("./pages/page/login/index"));
const BusinessUsersOnboardingPage = React.lazy(
  () => import("./pages/page/new-user/index")
);
const PermingPage = React.lazy(() => import("./pages/page/perming/index"));
const OrganizationDatabasesPage = React.lazy(
  () => import("./pages/page/organizations/databases/index")
);
const OrganizationDatabasesDetailPage = React.lazy(
  () => import("./pages/page/organizations/databases/show/teams/index")
);
const OrganizationDatabaseAddTeamPage = React.lazy(
  () => import("./pages/page/organizations/databases/show/add-teams/index")
);
const OrganizationPermissionsPage = React.lazy(
  () => import("./pages/page/organizations/teams/index")
);
const OrganizationUsersPage = React.lazy(
  () => import("./pages/page/organizations/users/index")
);

const OrganizationUsersTeamsPage = React.lazy(
  () => import("./pages/page/organizations/users/show/teams/index")
);

const OrganizationUsersAddTeamPage = React.lazy(
  () => import("./pages/page/organizations/users/show/add-teams/index")
);

const OrganizationUsersDatabasesPage = React.lazy(
  () => import("./pages/page/organizations/users/show/databases/index")
);

const OrganizationTeamsUserPage = React.lazy(
  () => import("./pages/page/organizations/teams/show/users/index")
);

const OrganizationTeamsDatabasesPage = React.lazy(
  () => import("./pages/page/organizations/teams/show/databases/index")
);

const OrganizationTeamsAddUserPage = React.lazy(
  () => import("./pages/page/organizations/teams/show/add-users/index")
);

const OrganizationTeamsAddDatabasesPage = React.lazy(
  () => import("./pages/page/organizations/teams/show/add-databases/index")
);

const DTCProductTablePage = React.lazy(
  () =>
    import(
      "./pages/page/product/data-collection/[productName]/[section]/[tableName]/index"
    )
);
const DTCProductSectionPage = React.lazy(
  () =>
    import("./pages/page/product/data-collection/[productName]/[section]/index")
);
const TableProductPage = React.lazy(
  () =>
    import(
      "./pages/page/product/database/[productName]/[section]/[datasetCode]/index"
    )
);
const TableProductSectionPage = React.lazy(
  () => import("./pages/page/product/database/[productName]/[section]/index")
);
const PublishersPage = React.lazy(
  () => import("./pages/page/publishers/index")
);

const SearchPage = React.lazy(() => import("./pages/page/search"));
const SearchV1Page = React.lazy(
  () => import("./pages/page/search/old-search-index")
);

const SearchApiKeysPage = React.lazy(
  () => import("./pages/page/search-api-keys/index")
);
const SignUpPage = React.lazy(() => import("./pages/page/signup/index"));
const InvitedPage = React.lazy(() => import("./pages/page/invited/index"));

const PublisherPage = React.lazy(() => import("./pages/publisher/[pageId]"));
const ResourcesPage = React.lazy(() => import("./pages/page/resource-hub"));
const ResourcesShowPage = React.lazy(
  () => import("./pages/page/resource-hub/[slug]/index")
);
const ProductPage = React.lazy(() => import("./pages/databases/[slug]"));

const DatatableExportPage = React.lazy(
  () => import("./pages/tables/[datatable]/[code]/export")
);

const DTCLicencePage = React.lazy(
  () =>
    import("./pages/page/product/data-collection/[productName]/licence/index")
);

const TableLicencePage = React.lazy(
  () => import("./pages/page/product/database/[productName]/licence/index")
);

const TermsPage = React.lazy(() => import("./pages/page/terms/index"));
const EmergencyEmailListPage = React.lazy(
  () => import("./pages/page/emergency-email-list/index")
);

const Routes = () => {
  useTrackCurrentPage();

  return (
    <Switch>
      <Route exact key="/" path="/" component={HomePage} />
      <ProtectedRoute
        exact
        key="/account"
        path="/account"
        component={AccountPage}
      />
      <ProtectedRoute
        exact
        key="/account/organizations"
        path="/account/organizations"
        component={AccountOrganizationsPage}
      />
      <ProtectedRoute
        exact
        key="/account/password/"
        path="/account/password/"
        component={AccountPasswordPage}
      />
      <ProtectedRoute
        exact
        key="/account/profile/"
        path="/account/profile/"
        component={AccountProfilePage}
      />
      <ProtectedRoute
        exact
        key="/account/subscriptions/"
        path="/account/subscriptions/"
        component={AccountSubscriptionsPage}
      />
      <ProtectedRoute
        exact
        key="/account/subscriptions/billing"
        path="/account/subscriptions/billing"
        component={AccountSubscriptionsBillingPage}
      />
      <ProtectedRoute
        exact
        key="/account/subscriptions/invoices"
        path="/account/subscriptions/invoices"
        component={AccountSubscriptionsInvoicePage}
      />
      <ProtectedRoute
        exact
        key="/account/subscriptions/invoices/:invoiceId"
        path="/account/subscriptions/invoices/:invoiceId"
        component={AccountSubscriptionsInvoiceDetailPage}
      />
      <ProtectedRoute
        exact
        key="/account/sftp-settings"
        path="/account/sftp-settings"
        component={AccountSftpSettingsPage}
      />
      <ProtectedRoute
        exact
        key="/bookmarks"
        path="/bookmarks"
        component={BookmarksPage}
      />
      <Route
        exact
        key="/databases/:slug"
        path="/databases/:slug"
        component={ProductPage}
      />
      <Route
        exact
        key="/tables/:datatable/:code/export"
        path="/tables/:datatable/:code/export"
        component={DatatableExportPage}
      />
      <Route
        exact
        key="/tables/:code/export"
        path="/tables/:code/export"
        component={DatatableExportPage}
      />
      <PublicOnlyRoute
        exact
        key="/forgot-password"
        path="/forgot-password"
        component={ForgotPasswordPage}
      />
      <PublicOnlyRoute
        key="/reset-password"
        path="/reset-password"
        component={ResetPasswordPage}
      />
      <RedirectWithQuery from="/users/password/edit" to="/reset-password" />
      <AdminRoute
        exact
        key="/json-widget"
        path="/json-widget"
        component={JsonWidgetPage}
      />
      <PublicOnlyRoute exact key="/login" path="/login" component={LoginPage} />
      <AdminRoute
        exact
        key="/business-users-onboarding"
        path="/business-users-onboarding"
        component={BusinessUsersOnboardingPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/databases/"
        path="/organizations/:orgName/databases/"
        component={OrganizationDatabasesPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/databases/:databaseId"
        path="/organizations/:orgName/databases/:databaseId"
        component={OrganizationDatabasesDetailPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/databases/:databaseId/add-teams"
        path="/organizations/:orgName/databases/:databaseId/add-teams"
        component={OrganizationDatabaseAddTeamPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/teams/"
        path="/organizations/:orgName/teams/"
        component={OrganizationPermissionsPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/users/"
        path="/organizations/:orgName/users/"
        component={OrganizationUsersPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/users/:usersId/teams"
        path="/organizations/:orgName/users/:usersId/teams"
        component={OrganizationUsersTeamsPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/users/:usersId/add-teams"
        path="/organizations/:orgName/users/:usersId/add-teams"
        component={OrganizationUsersAddTeamPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/users/:usersId/databases"
        path="/organizations/:orgName/users/:usersId/databases"
        component={OrganizationUsersDatabasesPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/users/invite-users"
        path="/organizations/:orgName/users/invite-users"
        component={OrganizationUsersPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/teams/:permissionId/users"
        path="/organizations/:orgName/teams/:permissionId/users"
        component={OrganizationTeamsUserPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/teams/:permissionId/databases"
        path="/organizations/:orgName/teams/:permissionId/databases"
        component={OrganizationTeamsDatabasesPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/teams/:permissionId/add-users"
        path="/organizations/:orgName/teams/:permissionId/add-users"
        component={OrganizationTeamsAddUserPage}
      />
      <ProtectedRoute
        exact
        key="/organizations/:orgName/teams/:permissionId/add-databases"
        path="/organizations/:orgName/teams/:permissionId/add-databases"
        component={OrganizationTeamsAddDatabasesPage}
      />
      <ProtectedRoute
        exact
        key="/perming"
        path="/perming"
        component={PermingPage}
      />
      <Route
        exact
        key="/tables/:productName/:tableName"
        path="/tables/:productName/:tableName"
        component={DTCProductTablePage}
      />
      <Route key="/databases/:productName/" path="/databases/:productName/">
        <Switch>
          <Route
            exact
            path="/databases/:productName/"
            component={DTCProductSectionPage}
          />
          <Route
            exact
            key="/databases/:productName/:section"
            path={[
              "/databases/:productName/documentation",
              "/databases/:productName/usage",
              "/databases/:productName/usage/quickstart",
              "/databases/:productName/usage/quickstart/api",
              "/databases/:productName/usage/quickstart/python",
              "/databases/:productName/usage/quickstart/r",
              "/databases/:productName/usage/quickstart/excel",
              "/databases/:productName/usage/export",
              "/databases/:productName/data",
              "/databases/:productName/overview"
            ]}
            component={ProductSubrouteRedirect}
          />
          <Route
            exact
            key="/databases/:productCode/license/:licenseId"
            path="/databases/:productCode/license/:licenseId"
            component={DTCLicencePage}
          />
          <Route
            exact
            key="/databases/:productCode/pricing/checkout/"
            path="/databases/:productCode/pricing/checkout/"
            component={DTCProductPricingCheckoutPage}
          />
          <Route
            exact
            key="/databases/:productCode/pricing/thankyou/"
            path="/databases/:productCode/pricing/thankyou/"
            component={DTCProductPricingThankYouPage}
          />
          <Route
            exact
            key="/databases/:productCode/pricing/plans/"
            path="/databases/:productCode/pricing/plans/"
            component={DTCProductPricingPlansPage}
          />
          <Route
            exact
            key="/databases/:productCode/pricing/terms"
            path="/databases/:productCode/pricing/terms"
            component={DTCTermPage}
          />
        </Switch>
      </Route>
      <Route key="/data/:productName/" path="/data/:productName/">
        <Switch>
          <Route
            path={[
              "/data/:productName",
              "/data/:productName/documentation",
              "/data/:productName/usage",
              "/data/:productName/usage/quickstart",
              "/data/:productName/usage/quickstart/api",
              "/data/:productName/usage/quickstart/python",
              "/data/:productName/usage/quickstart/r",
              "/data/:productName/usage/quickstart/excel",
              "/data/:productName/usage/export"
            ]}
            exact
            component={TableProductSectionPage}
          />
          <Route
            exact
            key="/data/:productName/documentation"
            path="/data/:productName/documentation"
            component={ProductSubrouteRedirect}
          />
          <Route
            exact
            key="/data/:productName/:tableName/"
            path="/data/:productName/:tableName/"
            component={TableProductPage}
          />
          <Route
            exact
            key="/data/:productName/pricing/plans/"
            path="/data/:productName/pricing/plans/"
            component={TimeSeriesPlanPage}
          />
          <Route
            exact
            key="/data/:productName/pricing/terms"
            path="/data/:productName/pricing/terms"
            component={TableTermPage}
          />
          <Route
            exact
            key="/data/:productName/license/:licenseId"
            path="/data/:productName/license/:licenseId"
            component={TableLicencePage}
          />
        </Switch>
      </Route>
      <Route
        key="/organizations-db/:slug"
        path="/organizations-db/:slug"
        component={OrganizationDashboard}
      />
      <Route
        exact
        key="/publishers/"
        path="/publishers/"
        component={PublishersPage}
      />
      <Route exact key="/search/" path="/search/" component={SearchPage} />
      <Route
        exact
        key="/search-v1/"
        path="/search-v1/"
        component={SearchV1Page}
      />
      <AdminRoute
        exact
        key="/search-api-keys"
        path="/search-api-keys"
        component={SearchApiKeysPage}
      />
      <PublicOnlyRoute
        exact
        key="/sign-up"
        path="/sign-up"
        component={SignUpPage}
      />
      <Route key="/invited" path="/invited" component={InvitedPage} />
      <Route
        exact
        key="/publishers/:pageId"
        path="/publishers/:pageId"
        component={PublisherPage}
      />
      <Route
        exact
        key="/resources"
        path="/resources"
        component={ResourcesPage}
      />
      <Route
        key="/resources/:type/:slug"
        path="/resources/:type/:slug"
        component={ResourcesShowPage}
      />
      <Route exact key="/terms" path="/terms" component={TermsPage} />
      <AdminRoute
        key="/emergency-email-list"
        path="/emergency-email-list"
        component={EmergencyEmailListPage}
      />
      <Route component={ContentfulPageOrNoMatch} />;
    </Switch>
  );
};

export default Routes;
